//
// Icon buttons
//

// General styles

.btn {
   position: relative;
   text-transform: $btn-text-transform;
   transition: $transition-base;
   letter-spacing: $btn-letter-spacing;
   font-size: $input-btn-font-size;
   will-change: transform;

   &:hover {
      @include box-shadow($btn-hover-box-shadow);
      transform: translateY($btn-hover-translate-y);
   }

   &:not(:last-child) {
      margin-right: .5rem;
   }


   // Icons

   i:not(:first-child),
   svg:not(:first-child) {
      margin-left: .5rem;
   }

   i:not(:last-child),
   svg:not(:last-child) {
      margin-right: .5rem;
   }
}


// Remove translateY and margin animation when btn is included in a btn-group or input-group

.btn-group,
.input-group {
   .btn {
      margin-right: 0;
      transform: translateY(0);
   }
}


// Size variations

.btn-sm {
   font-size: $input-btn-font-size-sm;
}

.btn-lg {
   font-size: $input-btn-font-size-lg;
}


// Some quick fixes (to revise)

// Fixes
[class*="btn-outline-"] {
   border-width: 1px;
}

.btn-outline-secondary {
   color: darken(theme-color("secondary"), 50%);
}

.btn-inner--icon {
   i:not(.fa) {
      position: relative;
      top: 2px;
   }
}

.btn-link {
   font-weight: $btn-font-weight;
   box-shadow: none;

   &:hover {
      box-shadow: none;
      transform: none;
   }
}

.btn-neutral {
   color: theme-color("primary");
}
.btn-circle {
   width: 50px;
   height: 50px;
   padding: 6px 9px;
   border-radius: 50px;
   text-align: center;
   font-size: 29px;
   line-height: 1.42857;
}
.btn-circle.btn-xl {
   width: 70px;
   height: 70px;
   padding: 10px 16px;
   border-radius: 35px;
   font-size: 24px;
   line-height: 1.33;
}
