body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
.content {
  padding: 20px;
}
 
.header {
  padding: 10px;
  background: #edf2f4;
  border-bottom: 1px solid #999;
}
.header a {
  color: #0072ff;
  text-decoration: none;
  margin-left: 20px;
  margin-right: 5px;
}
.header a:hover {
  color: #8a0f53;
}
.header small {
  color: #666;
}
.header .active {
  color: #2c7613;
}
select.form-control,
textarea.form-control,
input.form-control {
  font-size: 1rem;
}
input[type=file] {
  width: 100%;
}